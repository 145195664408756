/* FORMS */

.form-wrapper {
    //margin: 0 0 2rem;
    
    &--clean {
        border: none;
    }

    &--contact {

    }

    &__row {
        padding: 0.5rem 1rem 1rem;
    }

    &__help {
        text-align: right;
        padding: 0 0.2rem 0.5rem;
        &--required {
            font-size: $fontSizeReduced;
            margin-bottom: 0.25rem;
            &:before {
                content: "*";
                margin-right: 0.25rem;
                display: inline-block;
                color: $txtColorBase-brand;
                font-weight: bold;
            }
        }
        &--description {
            font-size: $fontSizeReduced;
        }
    }
}

.form {
    position: relative;
    display: block;

    &.--full-width {
        max-width: 100%;
    }
    
    &--contact {

    }

    &--review {
        .form-group--checkbox {
            margin-top: -0.25rem;
            padding-left: 0.4rem;
            
        }
    }

    &--sendtofriend {
        
    }

    &--press {
        select,
        option {
            width: calc(100% + 2px);
            margin-right: -2px;
            font-size: 0.8rem;
        }

        option:first-child {
            font-style: italic;
            color: #CCC;
        }
    }

    &--sendtofriend {
        .form-row {
            //border: solid 1px blue;
            margin-left: -0.25rem;
            margin-right: -0.025rem;

            &--preview {
                border: solid 1px #CCC;
                padding: 1rem;
                margin: 0 0.125rem 1rem;
                font-size:  $baseFontSizeReduced;
            }
        }

        .form-column {
            //border: solid 1px red;
            min-width: auto;
            flex: 1;
            margin: 0 0.25rem;
            &:first-child,
            &:last-child {
                min-width: auto;
                margin: 0 0.25rem;
            }
        }

        p {
            margin: 0 0 0.5rem;
        }
    }

    &__title {

    }

    &__subtitle {
        display: block;
        text-transform: uppercase;
        font-size: $fontSizeReduced;
        + .form__title {
            margin-top: 0;
        }
    }

    &__fieldset-header {
        margin: 2rem 1rem;
    }

    &__fieldset-title {
        font-weight: bold;
        margin-bottom: 1rem;
        +.form-group {
            margin-top: 0.5rem;
        }
    }

    &__fieldset-subtitle {
        display: block;
        font-size: $baseFontSizeReduced;
        margin: -1rem auto 1rem;
        
        +.form-group {
            margin-top: 0.5rem;
        }
    }

    &__feedback {
        padding: 1rem;

        //SbC feedback centered on page
        position: relative;
        padding: 1rem;

        &--error {
            color: red;
        }

        &--success {
            color: green;
            font-weight: bold;
        }

        &--followup {
            color: #000;
            display: block;
        }

        span {
            display: block;
        }

        &--inline {
            padding: 0 1rem 1rem;
            font-size: $fontSizeReduced;
            .--success {
                color: green;
            }
        }
    }

    &__feedback--dialog {
        //SbC feedback centered on page
        position: fixed;
        top: 50%;
        padding: 1rem 3rem 1rem 1rem;
        background-color: #FFF;
        border: solid 1px #CCC;
        left: 50%;
        transform: translate(-50%, -50%);

        &--error {
            color: red;
        }

        &--success {
            color: green;
            font-weight: bold;
        }

        span {
            display: block;
        }

        &--inline {
            padding: 0 1rem 1rem;
            font-size: $fontSizeReduced;
            .--success {
                color: green;
            }
        }

        &.--inline {
            position: relative;
            border: none;
            left: 0;
            transform: none;
            background-color: transparent;
            .btn--close {
                display: none;
            }
        }

    }

    &__alternative-actions {
        padding: 1rem 0;
        font-size: 1rem;
        .link--read-more,
        .link--back {
            font-size: 1rem;
            color: #000;
        }
    }
}

.form-section {
    margin: 1rem 0;
}

.form-row {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: (64px*8)) {
        flex-direction: row;
    }

    &--input {
        position: relative;
        //max-width: 30rem;
        
        label {
            min-width: 12rem;
        }

        input {
            &.--required {
                background-color: rgb(#00a2e0, 0.15);
            }
        }

        &-captcha {
            margin-top: 2rem;
        }

        &.--error {
            label {
                color: red;
            }
            input {
                border: solid 1px red;
                background-color: #FFF;
            }
        }

        
    }

    &--checkbox {
        + .form-row--checkbox {
            margin-top: -1rem;
        }
    }

    &--select {
        position: relative;
        //max-width: 30rem;
        
        .form-group {
            text-align: right;
            select {
                height: 1.5rem;
                border-color: #CCC;
                margin-right: -2px;
            }
        }
    }

    &--buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        margin: 1rem 0 0;

        .btn {
            min-width: 8rem;
        }
    }

    &--help {
        margin-top: 1rem;
        &__text {
            font-size: $fontSizeReduced;
        }

        &.--incomplete {
            display: flex;
            font-weight: bold;
        }
    }

    &--feedback {
        flex-direction: column;
        margin-top: 1rem;
        font-size:  $baseFontSizeReduced;
        span {
            font-size:  $baseFontSizeReduced;
        }
    }

    &--fb-error {
        display: block;
        margin-top: 1rem;
        color: red;
        font-size: $baseFontSizeReduced;
    }

    &--error {
        display: block;
        margin-top: 1rem;
        color: red;
        font-size: $baseFontSizeReduced;
        padding-left: 1rem;
    }

    .feedback {
        &__title {
            
        }
        &__section {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;   
            }
        }
    }

    + .form-field--error {
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;

        + .form-field--error {
            margin-top: -0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.form-column {
    flex: 1;
    position: relative;

    &:first-child {
        min-width: calc(40% - 1rem);
        margin-right: 1rem;
    }
    &:last-child {
        min-width: 60%;
    }

    &--feedback {
        display: flex;
        flex-direction: row;
        font-size:  $baseFontSizeReduced;
        justify-content: flex-end;
    }

    &__input-non-editable {
        display: block;
        font-size:  $baseFontSizeReduced;
        padding: 0.1rem 0.5rem;
    }
}

.form-label {
    &.--required {
        &:after {
            content: "*";
            margin-left: 0.25rem;
            display: inline-block;
            color: $txtColorBase-brand;
            font-weight: bold;
            position: absolute;
        }
    }
}

.form-field {
    &--set {
        display: flex;
    }

    &--error {
        display: block;
        color: red;
        font-size: $baseFontSizeReduced;

        &.--pw {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }
    }

    &--clue {
        display: block;
        font-size: $baseFontSizeReduced;
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
        color: $grassgreen;
    }

    &--success {
        display: block;
        color: $grassgreen;
        font-size: $baseFontSizeReduced;

        &.--email-unknown {
            color: $txtColorBase-branded;
        }
    }

    &--suggestion {
        display: block;
        font-size: $baseFontSizeReduced;
        margin-bottom: 0.5rem;
    }
}

.form-fieldset {
    border: solid 1px #CCC;
    border-radius: 0.3rem;
    margin: 0;
    padding: 1rem;

    &--address,
    &--business,
    &--password {
        background-color: #DDD;
        
        &.expanded {
            display: block;
            border: none;
            border-radius: 0 0 0.3rem 0.3rem;
        }
        &.collapsed {
            display: none;
        }
    }

    &--notification {
        background-color: #DDD;
    }

    + .form-fieldset {
        margin-top: 1rem;
    }
}

.form-button {
    &--edit {
        margin-left: 1rem;
        color: red;
        cursor: pointer;
    }
}

.form-link {
    &--text {
        font-size: $baseFontSizeReduced;
        cursor: pointer;
        text-decoration: underline;
        width: 100%;
        text-align: right;
        display: block;
        margin-bottom: 0.5rem;
    }
}

// INPUTS

.form-field {
    &--text {
        padding: 0.25rem 0.5rem;
        border: solid 1px #CCC;
        width: calc(100% - 1rem);

        &::placeholder {
            font-style: italic;
            color: #BBB;
        }

        &.--required {
            &::placeholder {
                color: $txtColorBase-brand;

                @at-root .--error & {
                    color: red;
                }
            }
        }

        &.--int-small {
            width: 2rem;
        }

        &.--error {
            border: solid 1px red;
        }

        &.--readonly {
            border: none;
            padding-left: calc(0.5rem + 1px);
        }

        &:focus {
            &.--readonly {
                outline: none;
            }
        }
    }

    &--textarea {
        padding: 0.25rem 0.5rem;
        border: solid 1px #CCC;
        min-height: 150px;
        min-width: calc(100% - 1rem);
        max-width: calc(100% - 1rem);

        &.--required {
            color: $txtColorBase-brand;
            background-color: rgb(#00a2e0, 0.15);

            @at-root .--error & {
                color: red;
            }

            &::placeholder {
                color: $txtColorBase-brand;
            }
        }

        &::placeholder {
            font-family: Arial;
            font-style: italic;
            color: #BBB;
        }
    }
}

.form-group {
    flex: 1;
    position: relative;

    &--radio {
        .form-group__option {
            display: flex;
            align-items: flex-start;
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
            label {
                order: 2;
                margin-top: 0.85rem;
                cursor: pointer;
                @media only screen and (min-width: (64px*6)) {
                    margin-top: 0;
                }
            }
            input {
                order: 1;
                margin: 1.2rem 1rem 1rem 1rem;
                cursor: pointer;
                @media only screen and (min-width: (64px*6)) {
                    margin: 0.5rem 0.35rem 0 1rem;
                }
            }
        }

        &__description {
            display: block;
            font-size: $fontSizeReduced;
            color: $txtColorBase-positive;
            font-weight: bold;
            margin-bottom: 0.4rem;
        }
    }

    &--checkbox {
        display: flex;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0.5rem;

        input {
            position: relative;
        }

        label {
            min-width: 0;
            padding-right: 0;
            .--header {
                display: block;
                margin-bottom: 0.5rem;
                font-size: 1rem;
            }

            &.--reduced {
                font-size: $fontSizeReduced;
                line-height: 1.25rem;
            }

            &.--subscription {
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
            }

            span {
                display: inline;
            }
        }

        + label {
            font-size: $fontSizeReduced;
            margin-top: 0.5rem;
            margin-left: 0.25rem;
        }

        &.--required {
            &:before {
                content: "*";
                position: absolute;
                left: -0.5rem;
                top: -0.5rem;
                display: inline-block;
                color: red;
                font-weight: bold;
            }
        }
    }

    &__secret-switch {
        position: absolute;
        width: 1.5rem;
        height: 1.3rem;
        right: 0;
        top: 0.25rem;
        font-size: $fontSizeReduced;
        cursor: pointer;
        background-image: url('../../images/icons/ic-eye-black-2.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.3rem;
        display: block;
        text-indent: -999rem;
        opacity: 0.3;
        &.--active {
            opacity: 1
        }

        @media only screen and (min-width: (64px*8)) {
            right: -1.85rem;
        }
    }

    &.--horizontal {
        display: flex;
    }
}
