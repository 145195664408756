// DIALOGS

.wrapper-dialog {
    border: solid 1px red;
    width: 200px;
    height: 200px;
}

.dialog {
    &--content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        border: solid 1px #AAA;
        border-radius: 0.25rem;
        padding: 2rem;
    }
}

.dialog-content {
    .form-group--checkbox {
        margin: 0;
        padding: 0;
    }

    input[type="checkbox"] {
        width: 1.75rem;
        margin: 0 0.25rem 0 -0.1rem;
        padding: 0;
    }
}

.dialog-content__label--checkbox {
    font-size: 1rem;
    font-weight: bold;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
}

