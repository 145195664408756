// SbC NAVIGATION

.wrapper-navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 1rem;;

    .navigation {
        &__list {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0 auto;
            list-style-type: none;
            text-transform: uppercase;
        }

        &__listitem {
            margin: 0 1rem;
            display: none;

            @media only screen and (min-width: $triggerMobileNav) {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 1;
            }

            a {
                color: #333333;
                text-decoration: none;
            }

            &--mobile-nav-trigger {
                display: block;

                @media only screen and (min-width: $triggerMobileNav) {
                    display: none;
                }
            }
        }
    }

    .navigation--mobile {
        &__list {
            position: absolute;
            top: calc(100% + 1rem);
            width: calc(100% - 2rem);
            display: none;
            flex-direction: column;
            padding: 1rem;
            margin: 0 auto;
            list-style-type: none;
            text-transform: uppercase;
            background-color: $bgColorBrandNav;
            border-bottom: solid 1px #CCC;

            @media only screen and (min-width: $triggerMobileNav) {
                display: none;
            }

            &.--active {
                display: block;
            }
        }

        &__listitem {
            margin: 0.5rem 1rem;
            color: $txtColorBase-branded;

            a {
                color: #333333;
                text-decoration: none;
            }
        }
    }
}