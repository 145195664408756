//SbC BUTTONS

button {
    border-radius: $borderRadius;

    + button {
        margin-left: 0.5rem;
    }
}

.btn {
    &--primary {
        background-color: $bgColorInactive;
        color: #BBB;
        border: none;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;

        &.--active {
            background-color: $bgColorBrand;
            color: #FFF;
            cursor: pointer;
            &:hover {
                background-color: $bgColorBrandDark;
            }
        }

        &.--disabled {
            background-color: $bgColorInactive;
            color: #BBB;
            &:hover {
                background-color: $bgColorInactive;
            }
        }

        &.--incart {
            background-color: green;
            &:hover {
                background-color: green;
            }
        }
    }

    &--secundary {
        border: solid 1px #CCC;
        cursor: pointer;
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;

        &:hover {
            background-color: $bgColorBrandDark;
            color: #FFF;
        }
    }

    &--tertiary {
        background-color: $bgColorInactive;
        color: #BBB;
        border: none;
        padding: 0.25rem 1rem;

        &.--active {
            color: $txtColorBase-brand;
            background-color: transparent;
            border: solid 1px $borderColor-branded;
            cursor: pointer;
            &:hover {
                background-color: $bgColorBrandDark;
                color: #FFF;
            }
        }

        &.--disabled {
            background-color: transparent;
            border: solid 1px $borderColor-inactive;
            color: #BBB;
            &:hover {
                background-color: $bgColorInactive;
            }
        }

        &.--fixed {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }

        &.--view {
            background-color: green;
            color: white;
            padding: 0.75rem 1rem;
            margin-bottom: 1rem;
        }
    }
}

.dialog-footer__button {
    &--primary {
        background-color: $bgColorInactive;
        color: #BBB;
        border: none;
        padding: 0.75rem 1rem;

        &.--active {
            background-color: $bgColorBrand;
            color: #FFF;
            cursor: pointer;
            &:hover {
                background-color: $bgColorBrandDark;
            }
        }
    }
}
