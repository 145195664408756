// SbC VARIABLES

$triggerMobileNav: 40rem;

// directory settings
$fontDir: "../../fonts";
$imagesDir: "../../../images";

$fontSizeReduced: 0.9rem;
$baseFontSizeReduced: 0.9rem;
$baseFontSizeSmall: 0.8rem;

$borderRadius: 0.3rem;

//text colors
$txtColorBase-brand: #00a2e0;
$txtColorBase-branded: #0154ba;
$txtColorBase-positive: #000;
$txtColorBase-link: #00a2e0;

//background-colors
$bgColorBrand: #00a2e0;
$bgColorBrandLight: rgba(#00a2e0, 0.15);
$bgColorBrandNav: rgba(185,229,246);
$bgColorBrandDark: #0154ba;
$bgColorInactive: #EEE;

//borders
$borderColor-branded: #00a2e0;
$borderColor-inactive: #CCC;

:root {
    --h: 100;
    --s: 50%;
    --l: 50%;
}

$grassgreen: hsla(
    var(--h), 
    var(--s), 
    var(--l)
);