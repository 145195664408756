// ACCOUNT

.order-filter {
    display: flex;
    justify-content: flex-end;

    &__list {
        display: flex;
        list-style-type: none;
        margin: 1rem -0.25rem;
    }

    &__listitem {
        margin: 0 0.25rem;
        color: #CCC;

        &:first-child {
            margin-right: 0.5rem;
            color: #000;
        }

        &.--active {
            color: $txtColorBase-branded;
            outline: 1px solid $txtColorBase-branded;
            border-radius: 0.25rem;
            padding: 0 0.45rem 0.1rem;
        }
    }
}