.wrapper-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width: $triggerMobileNav) {
        grid-template-columns: 1fr 1fr;
    }

    &__explanation {
        grid-column: 1 / span 2;
        width: 100%;
        margin: -.5rem 0 0.5rem;
        text-align: center;
        font-size: $baseFontSizeSmall;
    }

    .wrapper-content--buttons {
        grid-column: 1 / span 2;
        text-align: center;
    }
}

.wrapper-product {
    flex: 1;

    .product__image {
        height: 18rem;

        img {
            height: 100%;
            filter: saturate(0.6);
        }
    }

    img {
        max-width: 300px;
    }

    &.product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @at-root .wrapper-content__column & {
            align-items: flex-start;
            margin-bottom: 2rem;
        }
    }

    &.product-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 0 1rem;;
    }

    .product {
        &__image {
        }

        &__data {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        &__data-price {
            position: relative;
            width: 100%;
            margin: 1rem 0;
            text-align: center;
            height: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .product-sticker {
                position: absolute;
                right: 20%;
                top: 0;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                background-color: green;
                color: #FFF;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(10deg);
            }
        }

        &__titles {
            flex: 1;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        &__subtitle {
            position: absolute;
            margin-top: -1.25rem;
            transform: translateX(-50%);
            font-size: $baseFontSizeSmall;

            &.--inactive {
                color: #CCC;
            }
        }

        &__prices {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1.25rem;
            width: 100%;
            max-width: 20rem;

            @at-root .wrapper-content__column & {
                margin: 1rem 0;
            }
        }

        &__price {
            display: block;
            font-size: 1.5rem;
            margin-top: -0.4rem;

            &:before {
                content: '\20AC';
                display: inline-block;
                margin-right: 0.5rem;
            }

            &--reduced {
                color: #AAA;

                s, strike {
                    text-decoration:none;
                    position:relative;
                }
                s::before, 
                strike::before {
                    top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
                    background:red; /*this is the color of the line*/
                    opacity:.7;
                    content: '';
                    width: 110%;
                    position: absolute;
                    height:.1em;
                    border-radius:.1em;
                    left: -5%;
                    white-space:nowrap;
                    display: block;
                    transform: rotate(-15deg);  
                }
                s.straight::before, 
                strike.straight::before {
                    transform: rotate(0deg);
                    left:-1%;
                    width:102%;
                }

                + .product__price {
                    margin-top: -0.75rem;
                }
            }

            &--unavailable {
                text-align: center;
            }

            &--addition {
                display: block;
                margin-top: -0.5rem;
                font-size: $baseFontSizeSmall;
            }

            &.--inactive {
                color: #CCC;
            }
        }
    }
}


.wrapper-product {
    .wrapper-content__columns {
        display: grid;
        grid-template-columns: 1fr; 
        grid-column-gap: 2rem;
        margin-bottom: 1.5rem;

        @media only screen and (min-width: $triggerMobileNav) {
            grid-template-columns: 1fr 2fr;
        }

        h3 {
            font-family: $brandfontLight;
            font-size: 1.5rem;
            font-weight: normal;
        }

        ul {
            margin-left: -1rem;
            li {
                h3 {
                    font-family: $brandfont;
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }
    }

    img {
        display: block;
        max-width: 300px;
        margin: auto;

        &.--inactive {
            filter: grayscale(100);
            opacity: 0.7
        }
    }
}