// SbC HEADER

.wrapper-footer {
    background-color: rgba(#CCC, 0.5);
    margin-top: 4rem;
    padding: 2rem 1rem 0 1rem;
    font-size: 0.8rem;

    @media only screen and (min-width: $triggerMobileNav) {
        padding: 2rem 0 0 0;
    }

    &__columns {
        display: flex;
        max-width: 40rem;
        margin: auto;

        &--sitemap {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: solid 1px #AAA;

            a {
                color: #000;
                text-decoration: none;
            }
        }

        &--colofon {
            margin: 2rem auto 0;
            padding-bottom: 2rem;
            
            line-height: 1.75;
        }
    }

    &__column {
        flex: 1;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }
     
}