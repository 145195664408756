// SbC LAYOUT

.wrapper {
    &-app {

        h1 {
            max-width: 40rem;
            //margin: 0 auto 0;
            //margin-bottom: 1rem;
        }
    }

    &-page {

    }

    &-content {
        max-width: 40rem;
        margin: auto;
        padding: 0.1rem 1rem;

        &--home {
            max-width: calc(100%);
            background-color: $bgColorBrandLight;
            border-bottom: solid 1px #DDD;

            .wrapper-content__columns {
                max-width: 40rem;
                margin: auto;
            }
        }

        &__columns {
            display: flex;
            flex-direction: row;
        }

        &__column {
            flex: 1;
        }

        &__tabs {
            max-width: 20rem;
            margin: -2rem auto 2rem;
            display: flex;
            flex-direction: row;
        }

        &__tab {
            margin: 0 1rem;
            a {
                text-decoration: none;
            }
            &:hover {
                font-weight: bold;
            }
            &.--active {
                font-weight: bold;
            }
        }
    }
}