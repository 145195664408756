// ORDER DATA

.wrapper-order {
    border: solid 1px #CCC;
    padding: 1rem;
    margin-bottom: 1rem;

    .order {
        &__meta {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            span:first-child {
                font-weight: bold;
            }
        }

        &__data {
            padding: 0.4rem 0 0.5rem;
            margin: 0.4rem 0 0.5rem;
            border-top: solid 1px #CCC;
            border-bottom: solid 1px #CCC;
        }

        &__totals {
            
        }

        &__total {
            display: flex;
            flex-direction: row;
            width: 100%;

            &--ordertotal {
                font-weight: bold;
                background-color: rgb(#00a2e0, 0.15);
                margin:  1rem -1rem 0;
                padding: 0.2rem 1rem 0.3rem;
            }
        }

        &__feedback {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
        }

        &__payment {
            font-size: $fontSizeReducedIntermediate;
            &.--open {
                color: red;
            }
            &.--closed {
                color: green;
            }
        }

        &__download {
            font-size: $fontSizeReducedIntermediate;
            display: flex;
            margin-bottom: -0.5rem;
            justify-content: flex-end;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .item  {
        &__description {
            flex: 1;
        }

        &__amount {
            flex: 1;
            text-align: right;
        }
    }

    .total {
        &__description {
            flex: 1;
        }

        &__amount {
            flex: 1;
            text-align: right;
        }
    }
}