// CART

// CART ITEM
.wrapper-cartitem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
 
    + .wrapper-cartitem {
        margin-top: 1rem;
    }

    .cartitem {    
        display: flex;
        //align-items: center;

        &__image {
            padding: 0 1rem;
            border: solid 1px #CCC;
            border-radius: 0.3rem;
            margin-right: 1rem;

            img {
                width: 100%;
            }
        }

        &__data {
            grid-column: 2 / -1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__data-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;

            @media only screen and (min-width: (64px*8)) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
        }

        &__descr {
            grid-column: 1 / span 3;
            line-height: 1.35rem;

            @media only screen and (min-width: (64px*8)) {
                grid-column: 1 / span 2;
            }
        }

        &__data-explanation {
            grid-template-columns: 1fr;
            display: flex;
            align-items: center;
            font-size: $baseFontSizeReduced;
        }

        &__amount {
            text-align: right;
            justify-content: flex-end;
            align-items: center;

            &--price {
                justify-content: flex-start;
            }

            .product__price--reduced {
                color: red;
                text-decoration: line-through;
                margin-right: 1rem;
            }
        }

        &__delete {
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
        }
    }
}

// CART TOTALS
.wrapper-cart-totals {
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: solid 1px #CCC;
}

.wrapper-cart-total {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and (min-width: (64px*8)) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &.cart-explanation {
        margin-top: 1.5rem;
        display: block;
        font-size: $fontSizeReduced;
    }

    &.cart-total {
        font-weight: bold;
        margin: 0.5rem 0;
        padding: 0.5rem 0;
        border-top: solid 1px #CCC;
        border-bottom: solid 1px #CCC;
    }

    .cart-subtotal {
        &__label {
            grid-column: 1;
            @media only screen and (min-width: (64px*8)) {
                grid-column: 4;
            }
        }

        &__amount {
            text-align: right;
        }

        &__explanation {
            grid-column: 1 / 4;
        }
    }
}