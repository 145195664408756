/*
 *   CONTENT
*/

.--link {
    cursor: pointer;
    color: $txtColorBase-link;
    text-decoration: none;
}

.--link-inline {
    cursor: pointer;
    color: $txtColorBase-link;
    text-decoration: underline;
}

.links {
    &--download {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

.link {
    &--download {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
        color: $txtColorBase-link;

        span {
            margin-left: 0.25rem;
        }

        @at-root .wrapper-product & {
            img {
                margin: 0 0.5rem 0 0;
            }
        }
    }
}

.ic {
    &--download {
        width: 1.5rem;
    }
}

.icon {
    background-size: 100% 100%;
    background: left center no-repeat;
    width: 1rem;
    height: 1rem;
    display: block;
    &--delete {
        background-image: url('../../images/icons/cross-black.svg');
    }
}

.wrapper-content {
    &--faq {
        h2 {
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1.5rem;
        }
    }
}

.wrapper-content {
    &__feedback {
        padding: 2rem 0;
        font-size: $fontSizeReduced;
    }

    &__column--start {
        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                margin: 0 0 0.35rem;
                line-height: 1;
            }
        }
        &:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 12rem;
                margin-top: 1rem;

                @media only screen and (min-width: $triggerMobileNav) {
                    max-width: 16rem;
                }
            }
        }
    }
}

.payment-info {
    padding: 1rem 0;
    border: solid 1px #CCC;
    border-radius: 0.25rem;

    &__list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    > div {
        margin-top: 1rem;
        padding: 0 1rem;
    }

    &__column {
        padding: 0 1rem;
        width: 50%;
    }
}

ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
    
    li {
        padding-left: 1rem;
    };
}