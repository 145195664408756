// CART SUMMARY

.wrapper-cart-summary {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    &:before {
        position: relative;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
        background: url('../../images/icons/ic-cart.png') no-repeat;
        background-size: cover;
    }

    &__amount {
        margin-right: 0.5rem;
        &:before {
            content: '\20AC';
            // content: '?';
            display: inline-block;
            position: relative;
            margin-right: 0.25rem;
        }
    }

    &__items {
        color: #CCC;
    }
}