// SbC HEADER

header {
    
}

.wrapper-header {
    //max-width: 50rem;
    //margin: 0 auto 2.5rem;
    position: relative;
    padding: 3rem 0 0;
    border-bottom: solid 1px #DDD;

    &__columns {
        max-width: 40rem;
        margin: auto;
    }

    .header {
        &__content {
            &--branding {
                display: flex;
                justify-content: space-between;
            }
        }

        &__logo {
            max-width: 15rem;
        }
    }
}