/* TYPOGRAPHY */

@font-face {
    font-family: 'TitilliumRegular';
    src: url('#{$fontDir}/Titillium/TitilliumWeb-Regular/TitilliumWeb-Regular.woff') format('woff'),
         url('#{$fontDir}/Titillium/TitilliumWeb-Regular/TitilliumWeb-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SansPro';
    src: url('#{$fontDir}/source-sans-pro/SourceSansPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SansProLight';
    src: url('#{$fontDir}/source-sans-pro/SourceSansPro-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SansProExtraLight';
    src: url('#{$fontDir}/source-sans-pro/SourceSansPro-ExtraLight.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: calc(14px + 0.25vw);
}

body {
    margin: 0;
    font-family: 'TitilliumRegular',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$brandfont: 'SansPro';
$brandfontLight: 'SansProLight';

$baseLineHeight: 1.45;

$baseFontSize: 1rem;
$baseFontSizeReduced: 0.8rem;

$fontSizeReduced: 0.8rem;
$fontSizeReducedIntermediate: 0.9rem;

h1 {
    font-family: $brandfontLight;
    font-size: 2.75rem;
    font-weight: normal;
}

h2 {
    font-family: $brandfontLight;
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0;
}

h1 + h2 {
    margin-top: 0;
}

p {
   margin: 0 0 1rem;
}